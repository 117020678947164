import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import parse from "react-html-parser"

const CollapseQuestion = props => {
  const { children, title, className, toggle: toggleCollapse, isOpen } = props
  const [toggle, setToggle] = useState(false || isOpen)

  const handleClickHide = () => {
    setToggle(true)

    if (toggleCollapse && typeof toggleCollapse === "function") {
      toggleCollapse(toggle)
    }
  }

  useEffect(() => {
    setToggle(isOpen)
  }, [isOpen])

  const renderChildren = () => {
    if (typeof children === "string") {
      return parse(children)
    }

    return children
  }

  return (
    <div className={`CollapseQuestion ${className}`}>
      <h3 className="CollapseQuestion-head">
        <button className="CollapseQuestion-title" onClick={handleClickHide}>
          {title}
        </button>
      </h3>
      <div className={`CollapseQuestion-body ${toggle ? "" : "hide"}`}>
        {renderChildren()}
      </div>
    </div>
  )
}

CollapseQuestion.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default CollapseQuestion
