export const data = price => {
  return [
    {
      title: "¿Qué es la Ley de Contrato de Crédito Inmobiliario (LCCI)?",
      content: `
    <div class="" style="display: block;">
    <p>
        La Ley 5/2019, de 15 de marzo, publicada en  el BOE-A-2019-3814 y posterior 6301,
            reguladora de los contratos de crédito  inmobiliario, traspone parcialmente la
            Directiva 2014/17/UE del Parlamento  Europeo y del Consejo y refuerza la
            protección del prestatario en todas las  fases en la relación entre prestamista y
            prestatario: publicidad,  comercialización e información precontractual,
            vigencia del contrato y, si la  hubiera, ejecución hipotecaria.</p>
        <p>La <a href="https://www.boe.es/diario_boe/txt.php?id=BOE-A-2019-6301" target="_blank" class="marked">
                <strong>Orden ECE/482/2019</strong></a> complementa, tras el RD
            309/2019 de 26 de abril, la transposición al ordenamiento jurídico  español de la
            directiva. Entre las novedades más relevantes destaca la  introducción de los
            requisitos de conocimientos y competencia.</p>
    </div>
    `,
    },
    {
      title: "¿A quién va dirigida?",
      content: `
      <div style="display: block;">
          <p>
              A todas aquellas personas que ofrezcan servicio dirigido  o prestados a clientes, o clientes
              potenciales, en territorio español por  establecimientos financieros de crédito y
              entidades de crédito españolas,  extranjeras o domiciliadas en otro Estado miembro de
              la Unión Europea que  operes, en su caso, mediante sucursales, agente, intermediario o en
              régimen de  libre prestación de servicios en relación con productos y servicios bancarios.
          </p>
          <p>
              Dependiendo del servicio que ofrezca el interesado,  existen dos tipos de perfiles de certificación:                        </p>
          <ul>
              <li><strong>INFORMADOR:  </strong>toda persona que tenga como finalidad dar  información, comercializar,
                  ser directivo o adoptar decisiones sobre la  concesión en préstamos inmobiliarios (mínimo de 50 horas
                  de formación).
              </li>
              <li><strong>ASESOR: </strong>toda persona que asesore sobre préstamos  inmobiliarios
                  (mínimo 65 horas de formación).
              </li>
          </ul>
      </div>
    `,
    },
    {
      title: "¿En qué consiste la Certificación LCCI?",
      content: `
      <div class="respuesta" style="display: block;">
          <p>
                            Realización de la formación obligatoria y superación del  examen de los conocimientos LCCI.</p>
                        <p><strong>La formación está divida en diez módulos diferentes: </strong> </p>
                        <ul>
                            <li>Conceptos básicos de crédito inmobiliario</li>
                            <li>Legislación de los contratos de crédito inmobiliario</li>
                            <li>El proceso de compra de propiedad</li>
                            <li>Tasación de las garantías</li>
                            <li>Registro de la propiedad y notarías</li>
                            <li>El mercado de crédito inmobiliario en España</li>
                            <li>Normas deontológicas del sector</li>
                            <li>Evaluación de la solvencia del prestatario</li>
                            <li>Influencias financieras y económicas en el mercado inmobiliario</li>
                            <li>Gobierno de productos: mercado objetivo y diseño de préstamos inmobiliarios<br>
                            </li>
                        </ul>
                        <p>
                            Existen diferentes exenciones que eximen de realizar  parte de la formación y del examen.
                            Dichas exenciones son acumulativas.
                            "<a href="/img/efpa-lcci-exenciones-formacion.png" target="_blank">Ver imagen Exenciones Formación</a>"
                           </p>
                        <ul>
                            <li>
                                <p><strong>Economista/MIFID:</strong>&nbsp;Su  trabajo está relacionado principalmente con
                                asesoramiento económico y fiscal.</p>
                                <p>Estará exento del módulo nº 9
                                    <strong>"<a href="/img/efpa-lcci-exenciones-formacion.png" target="_blank">Ver imagen Exenciones Formación</a>"</strong>.
                                </p><p> <em>Titulación requerida (una de las dos):</em>
                                    </p>
                                <ol>
                                    <li>Titulación de grado o máster en Ciencias Empresariales, Economía o Administración
                                        y Dirección de Empresas</li>
                                    <li>
                                        Certificación  MIFID II. (Con la/s Certificación/es EIP, EFA o EFP no será necesario
                                        aportar  este documento)
                                    </li>
                                </ol>
                            </li>
                            <li style="margin-top:20px;">
                                <p><strong>Abogado:</strong>&nbsp;Su trabajo está  relacionado principalmente con asesoramiento legal.
                                </p>
                                <p>
                                Estará exento de los módulos nº 2, 3 y 5
                                    <strong>"<a href="/img/efpa-lcci-exenciones-formacion.png" target="_blank">Ver imagen Exenciones Formación</a>"</strong>.
                                </p>
                                <p>
                                    <em>Titulación requerida:</em>
                                </p>
                                <ol>
                                    <li>Titulación de grado o máster en ciencias jurídicas</li>
                                </ol>
                            </li>
                            <li style="margin-top:20px;">
                                <p><strong>Profesional:</strong>&nbsp;Usted lleva más de 5 años trabajando en el sector.</p>
                                <p>Estará exento de los módulos nº 1 y 2
                                    <strong>"<a href="/img/efpa-lcci-exenciones-formacion.png" target="_blank">Ver imagen Exenciones Formación</a>"</strong>.
                                </p>
                                <p><em>Titulación requerida:</em></p>
                                <ol>
                                    <li>Certificado debidamente sellado por su entidad donde acredite que tiene mas de 5 años de
                                        experiencia en el sector inmobiliario o de crédito hipotecario.</li>
                                </ol>
                            </li>
                        </ul>
      </div>
    `,
    },
    {
      title: "¿Cómo realizar la inscripción?",
      content: `
      <div class="respuesta" style="display: block;">
          <ul>
              <li>
                  Para realizar la inscripción <a href="https://examenes.efpa.es/lcci/" target="_blank">accede al siguiente enlace</a>
              </li>
          </ul>
      </div>
    `,
    },
    {
      title: "Tarifas",
      content: `
      <div class="respuesta" style="display: block;">
          <p>
            Para la inscripción al examen, el importe será de ${price} € (IVA incluido) (todos los inscritos tendrán acceso a la formación).
          </p>
          <p>
              <i>Para tarifas especiales de entidades colaboradoras de EFPA España contactar con la secretaría.</i>
          </p>
      </div>
    `,
    },
    {
      title: "Requisitos para presentarse al examen",
      content: `
      <div class="respuesta" style="display: block;">
          <ul>
              <li>Realizar la inscripción al examen</li>
                            <li>Haber realizado la formación de 50h para informar o de 65h para asesorar (con las correspondientes exenciones) antes de la fecha que se le indique en la inscripción al examen.</li>
                            <li>Haber finalizado los estudios de educación secundaria (EGB, ESO).</li>
          </ul>
      </div>
    `,
    },
    {
      title: "Examen de Certificación Asesor/Informador EFPA-LCCI",
      content: `
      <div class="respuesta" style="display: block;">
          <ul>
                          <li>Realizada la inscripción al examen, podrá acceder a la formación.</li>
                          <li>Para poder realizar el examen, es  obligatorio haber completado la formación y presentar la documentación  requerida, antes de la fecha que se le indique.                            </li>
                          <li>EFPA España certificará la formación de cualquier centro acreditado que cumpla con los requisitos fijados por el Banco de España.</li>
                          <li>El examen será online  y constará de 130 preguntas para prestar asesoramiento y 100 preguntas para informar o
                              comercializar préstamos hipotecarios. <strong>Si dispone de alguna exención se reducirán el número de preguntas</strong>
                              <strong>"<a href="img/efpa-lcci-numero-preguntas.png" target="_blank">Ver imagen número de preguntas examen</a>"</strong>.</li>
                          <li>Todas las preguntas serán tipo test, con las cuatro posibles respuestas. Es requisito haber respondido bien al menos al 70% del examen.</li>
                          <li>Duración del examen: 3 horas.</li>
                          <li>No se realizarán revisiones de examen</li>
                          <li>Una vez superado el examen, será necesaria la firma del Código Ético y de la declaración de honorabilidad de EFPA España.</li>
                          <li>El Certificado se podrá descargar desde la Intranet de la Web de EFPA España una vez superado el examen.</li>
                          <li><strong><a href="https://www.efpa.es/descargas/NormasExamenesOnLine.pdf" target="_blank">Leer recomendaciones generales de los exámenes online</a></strong></li>
                      </ul>
                      <p><a href="https://www.efpa.es/videotutorial-examen-online" target="_blank">
                        <img src="https://www.efpa.es/descargas/boton-videotutorial-examenesonline.jpg" /></a>
                        </p>
      </div>
    `,
    },
  ]
}
