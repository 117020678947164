import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import TitleBlock from "../components/TitleBlock"
import Breadcrumb from "../components/Breadcrumb"
import Hero from "../components/Hero"
import CollapseQuestion from "../components/Collapse/CollapseQuestion"
import { data } from "../data/efpa-lcci-data"
import TitlePage from "../components/TitlePage"
import { useAxiosClient } from "../service/useAxiosClient"

export default function EfpaLcci({ location }) {
  const id = 9
  const [questions, setQuestions] = useState([])
  const [itemSelected, setItemSelected] = useState(-1)
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    {
      label:
        " Certificación Asesor/Informador EFPA-LCCI - Ley de Contrato de Crédito Inmobiliario",
    },
  ]
  const [{ data: dataPrice }] = useAxiosClient({
    url: `/professional-certificates/web/exam-price/?professional_certificate=${id}`,
    method: "GET",
  })
  useEffect(() => {
    if (dataPrice) {
      setQuestions(data(dataPrice))
    }
  }, [dataPrice])
  const toggle = index => () => {
    setItemSelected(index)
  }

  const rendertQuestions = () => {
    return (
      questions &&
      questions.map((item, i) => (
        <CollapseQuestion
          toggle={toggle(i)}
          isOpen={itemSelected === i}
          title={item.title}
        >
          {item.content}
        </CollapseQuestion>
      ))
    )
  }
  return (
    <Layout>
      <TitlePage
        title="EFPA-LCCI - Ley de Contrato de Crédito Inmobiliario"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Certificación Asesor/Informador EFPA-LCCI - Ley de Contrato de Crédito Inmobiliario"
          icon="far fa-id-card"
        />
        <div className="EfpaLcci">
          <span className="EfpaLcci-text">
            Disponible la plataforma del curso online para la obtención del
            Certificado Asesor/Informador EFPA-LCCI (Ley de Contrato de Crédito
            Inmobiliario)
          </span>
          <div className="EfpaLcci-questions">{rendertQuestions()}</div>
        </div>
      </div>
    </Layout>
  )
}
